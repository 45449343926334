.empty-content {
  width: 100%;
  min-width: 80%;
  max-width: 1200px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 60px 40px;
  transition: height 0.5s ease;
  color: var(--shade-color);
  margin: 30px 0;

  &__icon {
    display: flex;
    justify-content: center;
    ion-icon {
      width: 90px;
      height: 90px;
      --ionicon-stroke-width: 7px;
    }
  }

  &__message {
    display: flex;
    justify-content: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.no-border-on-ssr {
  border: none;
}