.browse_page {
  .media_type_selector {

    margin-left: unset;
    margin-right: unset;
    margin-top: 30px;
    margin-bottom: 0;
    width: 100%;

    &__flexWrapper {
      display: flex;
      align-items: stretch;
      width: 100%;
    }

    &__bubble_wrapper {
      flex: 1;
    }

    &__media_type {

      width: 100px;
      height: 100px;

      &.selected {
        background-color: $primary-color;
        box-shadow: 0 0 0 2px $secondary-color, $active-shadow;
        margin-top: 2px;
      }

      &__text {
        top: 110px;
        font-size: 14px;
        overflow: visible;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.media_type_selector {
  margin-top: 40px;
  margin-bottom: 30px;

  display: block;
  padding: 0;

  overflow-x: auto;
  width: fit-content;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;

  &__bubble_wrapper {
    display: inline-block;
  }

  &__media_type {
    display: inline-block;
    text-align: center;
    padding: 0;

    width: 140px;
    height: 140px;

    border-radius: 70px;

    text-decoration: none;
    color: $fg-standard;
    transition: background-color 250ms;

    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 56px;

    position: relative;

    z-index: 0;

    background-color: $secondary-color;
    /* might want to use this instead of ::after trick
    background-image: var(--nav_background_url);
    background-size: cover;

    background-blend-mode: soft-light;
    */
    @supports not (background-image: var(--nav_background_url_webp)) {
      &::after {
        background-image: var(--nav_background_url_jpg)
      }
    }
    &::after {
      content: "";
      background-image: var(--nav_background_url_webp);
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 70px;
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: -1;
    }

    &:focus, &:hover {
      outline: 0;
      background-color: $primary-color;
    }

    &__text {
      font-size: $fz-standard;
      line-height: $lh-standard;
      font-weight: $fw-600;

      margin: auto;

      overflow: visible;
      display: flex;
      justify-content: center;
      hyphens: auto;

      position: absolute;
      width: 100%;
      top: 150px;
    }

  }
}


#media_type_discover_msg {
  width: 100%;
  color: $primary-dark;
  font-size: 2.7rem;
  text-align: center;
  margin-top: -100px;
  position: absolute;
  line-height: 1em;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.07, 1.07, 0.58, 1);
}
