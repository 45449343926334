// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.search {
  width: 100%;
  max-width: 700px;

  @media screen and (max-width: map_get($breakpoints, medium)) {
    max-width: calc(100vw - 40px);
  }

  @media screen and (max-width: map_get($breakpoints, small)) {
    min-width: 210px;
  }

}

.search_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-grow: 1;
}

.search_layout {
  background-color: $bg-standard;
  border-radius: 22px;
  height: 44px;
  border: $standard-border;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  z-index: 1;

  .search-outline {
    &-white {
      width: 20px;
      height: 20px;
    }
  }

  &__category {
    flex: none;
    order: 0;
    flex-grow: 0;
    font-style: $fs-standard;
    font-weight: $fw-standard;
    font-size: $fz-standard;
    background-color: $bg-standard;
    color: $fg-standard;
  }

  &__separator {
    border: $standard-border;
    background-color: $shade-color;
    transform: rotate(90deg);
    width: 22px;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 5px;

    @media screen and (max-width: map_get($breakpoints, medium)) {
      display: none;
    }

  }

  &__input {
    flex: none;
    order: 2;
    flex-grow: 1;
    margin: 0 10px;
    background-color: $bg-transparent;
    color: $fg-standard;
  }

  &__reset {
    flex: none;
    order: 3;
    flex-grow: 0;
    background-size: 26px !important;
  }

  &__submit {
    width: 32px;
    height: 32px;
    flex: none;
    order: 4;
    flex-grow: 0;
    margin: 0 10px;
    background: linear-gradient(to right, $primary-color 50%, $primary-dark, $primary-color);
    background-size: 300%;
    border-radius: 22px;

    .small {
      margin-left: 6px;
    }

    .large {
      margin-left: 10px;
    }

    &:active {
      background: linear-gradient(to right, $primary-dark 100%, $primary-color) !important;
    }

    &:hover {
      animation: primaryHover 2s linear;
    }

    @keyframes primaryHover {
      0% {
        background-position: 0;
      }

      100% {
        background-position: 180%;
      }
    }

  }

  &__submit-active {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 10px 6px 3px;
    width: 92px;
    height: 32px;
    flex: none;
    order: 4;
    flex-grow: 0;
    margin: 0 10px;
    background: linear-gradient(to right, $primary-color 50%, $primary-dark, $primary-color);
    background-size: 300%;
    border-radius: 22px;

    &:active {
      background: linear-gradient(to right, $primary-dark 100%, $primary-color) !important;
    }

    &:hover {
      animation: primaryHover 2s linear;
    }

    @keyframes primaryHover {
      0% {
        background-position: 0;
      }

      100% {
        background-position: 180%;
      }
    }

    &__text {
      flex: none;
      order: 1;
      flex-grow: 0;
      margin-right: 5px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      padding-bottom: 3px;

      color: $bg-standard;
    }

    &__icon {
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0 5px;
    }

  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    &__input {
      order: 0;
    }

    &__reset {
      order: 1;
    }

    &__submit {

      order: 2;

    }

  }

  #search_button {
    margin-right: 5px;
    order: 3;
    padding: 0;
    gap: 0;
    transition: padding 200ms ease 500ms, background 0.8s;

    .btn-text {
      overflow: hidden;
      max-width: 0;
      transition: max-width 200ms ease 500ms, background 0.8s;
    }
  }

  #clear_search_button {
    order: 2;
    margin-right: 5px;
  }

  &:focus-within {
    border-color: $bg-transparent;
    box-shadow: $active-shadow;
    -webkit-appearance: none;

    #search_button {
      padding: 4px 10px 4px 8.5px !important;

      .btn-text {
        max-width: 60px;
      }

      @media screen and (max-width: map_get($breakpoints, small)) {
        padding: 4px !important;

        .btn-text {
          max-width: 0;
        }
      }
    }
  }
}

#search_field, .search_subject {
  border: none;
  outline: none;
}

.search_subject::placeholder {
  font-weight: 100;
  color: $shade-color;
}

#search_field {
  margin-left: 15px;

  .vs--open {
    .vs__selected {
      position: relative !important;
    }


  }

  .vs__dropdown-toggle {
    align-items: center;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  .vs__selected-options {
    margin-right: 0;
  }


  .vs__dropdown-menu {

    position: absolute;
    z-index: 9;
    list-style-type: none;
    background-color: $bg-standard;
    box-shadow: $active-shadow;
    padding: 0;
    margin-top: 10px;

    li {
      display: block;
      padding: 10px 20px !important;
      line-height: $lh-standard;
      font-style: $fs-standard;
      font-weight: $fw-standard;
      font-size: $fz-standard;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .vs__fade-enter-active,
    .vs__fade-leave-active {
      transition: none;
    }

    .vs__search, .vs__search:focus {
      margin-right: 2px;
      padding: 0;
    }

  }

  @media screen and (max-width: map_get($breakpoints, medium)) {
    display: none;
  }

}


.home__search {
  #search_field, .search_layout__separator {
    display: none;
  }

  .search_subject {
    margin-left: 15px;
  }
}

.search_subject {
  flex-grow: 1;
  margin: 0 10px 0 0;
  padding: 0;
  font-family: $font-family;
  font-style: $fs-standard;
  font-weight: $fw-600;
  font-size: $fz-standard;
  line-height: $lh-standard;
  @media screen and (max-width: map_get($breakpoints, medium)) {
    margin: 0 10px;
    padding: 1px 2px;
  }
}


.search_sources {
  position: relative;

  height: 46px;

  margin-top: -5px;

  width: 100vw;
  overflow: auto;

  background-color: $bg-standard;

  #sources-menu {
    display: inline-flex;
    padding-left: 30px;

    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }

  li {
    list-style-type: none;
  }
}

.search_settings {
  margin-right: 65px;
  flex-grow: 0;

  padding: 5px;
  outline: none;
  position: relative;

  img {
    margin-right: 5px;
  }
}
