.confirm_purchase {
  border-radius: 20px;
  box-shadow: $active-shadow;
  background-color: $bg-standard;
  position: absolute;
  z-index: 100;
  padding: 20px;
  left: 0;
  top: 46px;
  white-space: normal;

  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 500px;

  .button__primary:disabled {
    background-color: $primary-color;
    color: white;
  }

  .empty-list {
    background-color: $shade-light !important;
    color: $shade-color !important;
  }
  
  @media screen and (max-width: 540px) {
    width: calc(100vw - 40px);
  }

  @media screen and (max-width: map_get($breakpoints, small)) {
    width: calc(100vw - 80px);
  }

  @media screen and (min-width: 600px) and (max-width: 1000px) {
    width: fit-content;
  }

  .spinner {
    margin: 20px 0;
  }

  & > * {
    margin: 0;
    padding: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: $shade-light;
    border-radius: $border-radius-22;
    padding: 20px;
  }

  &__submit_container {
    border-top: $standard-border;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: -20px;
    margin-right: -20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .button__secondary {
      margin-right: 20px;
    }

    &__cancel {
      @media screen and (max-width: map_get($breakpoints, small)) {
        display: none !important;
      }
    }


  }

  .empty {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  &__mandatory {
    font-weight: $fw-standard;
    font-size: $fz-12;
    color: $shade-dark;

    margin: 0 0 0 auto;
    padding: 0;

  }

  p {
    font-weight: $fw-700;
    margin-top: 0;
  }

}