// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

//TODO: need refactoring
.preview {
  width: 420px;
  border: $light-border;
  padding: 20px;
  margin-top: 75px;
  margin-right: $side-space;
  height: 90vh;
  overflow-y: hidden;

  .control-buttons {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    position: relative;
  }

  .arrow-button {
    margin-right: 15px;
  }

  .close-button {
    right: 0;
    top: 0;
    margin: -5px -5px 10px 0;
    position: absolute;
  }

  &__shadow {
    position: absolute;
    bottom: 1px;
    left: 1px;
    right: 81px;
    height: 40px;
  }


  &__buttons {
    justify-content: center;
  }

  &__img {
    width: 135px;
    height: 200px;
  }

  &__big-img {
    height: 540px;
    width: 420px;
    margin: 10px auto;
  }

  &__video {
    width: 320px;
    height: 180px;
  }

  &__big-video {
    width: 420px;
    height: 236px;
    margin: 10px auto;
  }

  &__title {
    margin: 10px 0;
    font-size: 18px;
    font-weight: $fw-700;
    text-align: center;
  }

  &__author {
    margin: 0;
    margin-bottom: 7px;
    font-style: $fs-italic;
    font-size: $fz-12;
    text-align: center;
  }


  &__div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }
}