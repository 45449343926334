@import "../mixins";

.form_element {
  margin-bottom: 20px;
  width: 100%;
  background-color: $bg-transparent;

  font: $standard-font;
  line-height: $lh-19;

  position: relative;

  & * {
    background-color: $bg-transparent;
    color: $fg-standard
  }

  &.primary {
    & .form_element__range_line__element {
      border-color: $primary-color;
      border-width: 2px;
    }
  }

  &__label {
    display: grid;
    grid-template-columns:25px 1fr;
    grid-auto-flow: dense;
    align-items: center;
    width: inherit;
    height: 100%;
    gap: 10px;

    &__text {
      display: block;
      margin-right: 4px;
      grid-column-start: 2;
    }

    span {
      display: block;
    }
  }

  &__text_input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 50;
  }

  &__prefix_icon {
    flex: 1;
    margin: 2px 10px 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close_button {
    flex: 1;
  }

  &__text-area {
    resize: vertical;
    height: 125px;
    min-height: 125px;
    width: 100%;
    background: $bg-transparent;
    border-radius: $border-radius-22;
    padding: 10px;
    font-family: $font-family;
    color: $fg-standard;
    border-color: $shade-color;
    outline: none;
  }

  &__range_input_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    button {
      margin-top: -10px;
    }
  }

  &.checkbox {
    width: auto;

    .form_element__label {

      &__text {
        align-self: center !important;
      }
    }

    .checkbox__icon {
      display: flex;

      &.error * {
        color: $danger-color;
      }

      &.checked * {
        color: $primary-color;
      }
    }
  }

  &__input-checkbox {
    visibility: hidden;
    display: block;
    width: 2em;
    height: 100%;
    opacity: 0;
  }

  &__input-radio {
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
  }

  &.checkbox_group {
    border: $standard-border;
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;

    &:focus-within {
      border-width: 0;
      box-shadow: $dropdown-shadow;
    }

    legend {
      color: $shade-dark;
      font-size: 10px;
      line-height: 12px;

      float: left;
      padding-inline: 0;
      padding-bottom: 5px;
    }

    div {
      margin-bottom: 0;
    }
  }

  &.text, &.dropdown, &__range_line__element, &.icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 42px;
  }

  &.text, &.dropdown, &__range_line__element {
    padding: 0 15px;
    border: $standard-border;
    border-radius: 50px;

    label {
      align-self: flex-start;
      color: $shade-dark;
      padding-bottom: 0;
      margin-bottom: 0;

      span {
        display: block;
        color: $shade-dark !important;
      }
    }

    select {
      height: 100%;
    }

    input, select {
      width: 100%;
      font: inherit;
      border: none;
      padding-top: 0;
      margin-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      font-size: $fz-14;

      &:focus {
        outline: unset;
      }

      &:focus-visible {
        outline: unset;
      }
    }
  }

  &.text {
    align-items: stretch;
    width: 100%;

    &.form_element-error {
      border-color: $danger-color;
    }

  }

  &.text, &__range_line__element {
    label {
      display: none;
    }
  }

  &.dropdown {
    label {
      align-self: center;
      z-index: 1;
    }
  }

  &__range_line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    margin-top: 0;

    input[type="date"]::-webkit-clear-button {
      display: none;
    }

    &__element {
      width: 49%;
      min-width: 9em;
      margin-top: 10px;
    }

  }

  &.text:focus-within,
  &.text.filled {
    padding-top: 4px;
    padding-bottom: 4px;

    label {
      font-size: 10px;
      line-height: 12px;
      display: block;
      width: 100%;
    }

    .button__icon {
      margin-top: -4px;
      margin-right: -8px;
    }

  }

  &.dropdown.filled,
  &.range:focus-within .form_element__range_line__element:focus-within,
  &.range.filled .form_element__range_line__element-filled {
    flex-direction: column;

    padding-top: 4px;
    padding-bottom: 4px;

    label {
      font-size: 10px;
      line-height: 12px;
      display: block;
      padding-top: 5px;
    }
  }

  &.text:focus-within,
  &.range:focus-within .form_element__range_line__element {
    border-color: rgba(0, 0, 0, 0);
    box-shadow: $active-shadow;
  }

}


.radio {
  &-container {
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 18px;
    border-radius: 10px;

    input {
      appearance: none;
    }

    ion-icon {
      color: $primary-color !important;
    }

    &:focus-within {
      box-shadow: $active-shadow;
    }

  }

  &__group {
    display: flex;
    row-gap: 10px;
  }
}

.dropdown {
  justify-content: space-between;
  padding: 0 15px;
  position: relative;
  cursor: pointer;

  &:focus-within {
    box-shadow: $active-shadow;
    border-color: transparent !important;
  }

  &-opened {
    color: $shade-dark;
  }

  ion-icon {
    font-size: 28px;
    color: $shade-dark
  }

  &__options {
    position: absolute;

    margin: 0;
    padding: 0;
    margin-block: 0;
    padding-inline: 0;
    margin-inline: 0;

    list-style: none;
    border-radius: 20px;
    border: none;

    font-size: 16px;

    width: 100%;
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;

    z-index: 2;
    top: 45px;

    background: $bg-standard;
    box-shadow: $dropdown-shadow;

    @include setupScrollBar();
  }

  &__option {
    padding: 8px 8px 8px 15px;
    min-height: 27px;
    line-height: normal;
    font-size: $fz-14;

    &:hover {
      background-color: $primary-color;
      color: $text-over-primary-color;
    }
  }

  &__option.highlight-option {
    color: $primary-color;
    text-decoration: underline;

    &:hover {
      background-color: $primary-color;
      color: $text-over-primary-color;
    }
  }

  .select-header {
    height: 100%;
    display: grid;
    width: 100%;

    .label {
      color: $shade-dark;
      font-size: $fz-12;
      line-height: $lh-16;
    }
  }

  .dropdown-label {
    .label {
      font-size: $fz-10;
      line-height: 12px;
    }
  }

  .selected-option {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0 !important;
    font-weight: 500 !important;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: normal;
  }
}

.form_element.dropdown.displayOnlyIcon {
  margin-bottom: 0;
  border: none;

  .tooltip, .select-icon-container {
    margin-bottom: 0;
    display: flex;
    visibility: visible;

    @media screen and (max-width: 1254px) {
      display: none;
      visibility: hidden;
    }
  }

  #sorting-mobile-toggler {
    border: none;
    display: none;
    visibility: hidden;

    @media screen and (max-width: 1254px) {
      display: flex;
      visibility: visible;
    }
  }

  @media screen and (max-width: 1254px) {
    border: none;
    box-shadow: none;
    margin-bottom: 0;
  }

  .dropdown__options {
    @media screen and (max-width: 1254px) {
      right: 0;
      display: flex;
      flex-direction: column;
      width: fit-content;
      border-radius: 20px;
      box-shadow: var(--active-shadow);

      li {
        white-space: nowrap;
      }
    }
  }
}



