//============================================================================
// This file consist of all payment forms. If you need to add some form's
// styling for payment process, do it here!
//============================================================================

//template for all windows inside payment main form
@mixin paymentPopup(
  $text-color: $fg-standard,
) {
  background-color: $bg-standard;
  color: $text-color;
  border-radius: $border-radius-20;
  font-style: $fs-standard;
  font-weight: $fw-600;
  font-size: $fz-14;
  line-height: $lh-standard;
  box-shadow: $active-shadow;
  position: relative;
}

@mixin displayGrid($template-columns: 1fr, $gap:5px) {
  display: grid;
  grid-template-columns: $template-columns;
  gap: $gap;
}


//main popup window
.payment {
  background-color: $bg-blocker;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  &__errors {

    &:empty {
      display: none;
    }

    .enter__error {
      margin-bottom: 0;
    }
  }

  &__template {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: $fz-14;

    .form_element {
      margin-bottom: 0;
    }
  }

  &__grid {
    display: grid;
    row-gap: 15px;
    column-gap: 5px;
    grid-auto-columns: 2fr 1fr;

    &__item {
      display: grid;
      grid-template-columns: 2fr 1fr;
      column-gap: 30px;
      align-items: center;
      height: fit-content;

      &.img {
        grid-template-columns: 1fr;
        grid-column: 2 / 3;
        grid-row: 1 / 6;
        z-index: 2;

        .payment__message {
          font-size: $fz-14;

          span {
            font-style: italic;
          }
        }
      }

      &.full-width {
        background-color: $secondary-light;
        z-index: 1;
        padding: 10px 0 10px 15px;

        .payment__message {
          font-weight: $fw-600;;
          font-size: 18px;
        }
      }

      &.price-info {

        .payment__message {
          font-weight: $fw-600;
          font-size: $fz-standard;
        }
      }

      &.border {
        height: 1px;
        background-color: $shade-light;
      }

      &.form {
        row-gap: 10px;
      }

      &.research-purpose {
        _grid-area: 7 / 1 / 8 / 3;
      }

      $children: 8;
      @for $n from 2 through $children {
        &:nth-child(#{$n}) {
          grid-area: #{$n - 1} / 1 / #{$n} / 3;
        }
      }

      @media screen and (max-width: map-get($breakpoints, "small")) {
        @for $n from 1 through $children {
          &:nth-child(#{$n}) {
            grid-area: #{$n} / 1 / #{$n + 1} / 3;
            grid-template-columns: 1fr;
          }
        }

        &.img {
          display: flex;
          align-items: flex-start;
        }
      }

      @media screen and (max-width: map-get($breakpoints, "xs")) {
        &.img {
          flex-direction: column;
        }
      }

    }
  }

  .full_size {
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    border-radius: 0;
    padding: 0;
    overflow: auto;

    .close {
      top: 24px;
      right: 5px;
    }
  }

  .status {
    display: flex;

    @media screen and (max-width: 530px) {
      display: none;
    }

    &__link {
      color: $shade-dark;
      font-weight: $fw-standard;

      &:hover, &.active {
        text-decoration: underline;
      }
    }
  }

  &__link {
    &:hover {
      text-decoration: underline;
    }

    &__secondary {
      @extend .payment__link;
      color: $secondary-color;
    }
  }

  .error {
    color: $danger-color !important;
  }

  .close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -60px;
    right: 0;
    width: 40px;
    height: 40px;
    background-color: var(--background-color);
    border-radius: 50%;
    box-shadow: var(--active-shadow);
    cursor: pointer;
  }

  &__popup {
    @include paymentPopup();
    padding: 30px;
    max-width: 680px;
    width: calc(100vw - 30px);
    height: fit-content;

    &.web-login-popup {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 0;
    }

    .enter__header {
      margin: 10px auto;
    }

    #header__login__buttons {
      .not_for_payment {
        display: none;
      }
    }

    .payment__person {
      display: flex;
      position: absolute !important;
      right: 70px;
      top: 25px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

    .form_element {
      margin-bottom: unset;
    }

    .radio-container {
      cursor: pointer;

      &:focus-within {
        box-shadow: none;
      }
    }

    @media screen and (max-width: map-get($breakpoints, xs)) {
      .radio__group {
        gap: 10px;

        .radio-container {
          column-gap: 8px;
        }
      }
    }


    &__line_1 {
      @include displayGrid(1fr 1fr)
    }

    &__line_2 {
      @include displayGrid(2fr 3fr 3fr);
    }

    &__line_3 {
      @include displayGrid(4fr 2fr);
    }

    &__line_4 {
      @include displayGrid(4fr 5fr 5fr);

      @media screen and (max-width: map-get($breakpoints, xs)) {
        grid-template-columns: 6fr 5fr 5fr;
        row-gap: 10px;

        div:nth-child(2) {
          grid-column: 2 / 4;
        }

        div:last-child {
          grid-row: 2;
          grid-column: 1 / 4;
        }
      }

      .form_element__label {
        width: 50px;
      }
    }

    &__line_5 {
      @include displayGrid()
    }

    &__info {
      font-weight: $fw-standard;
      font-size: $fz-10;
      line-height: $lh-16;
      color: $shade-dark;
      text-align: right;
    }

  }

  &__methods {

    .radio__group {
      gap: 40px;
    }

    @media screen and (max-width: map-get($breakpoints, xs)) {
      .payment__title {
        display: none;
      }
      h1 {
        display: none;
      }
    }

    &__body {
      display: flex;
      min-height: 60px;
      flex-direction: column;
      padding-top: 20px;

      .form_element.checkbox {
        background-color: $shade-light;
        margin: 15px 0 30px;

        label {
          display: grid;
          grid-template-columns: 25px 1fr;
          grid-auto-flow: dense;
          cursor: pointer;
          gap: 20px;


          .form_element__label__text {
            grid-column-start: 2;
          }
        }
      }

      .form_element {
        background-color: $bg-standard;
      }

      &__button {
        background: $bg-standard;
        height: 62px;
        width: 100%;

        &:last-child {
          img {
            height: 50px;
          }
        }


        img {
          height: 32px;
        }
      }
    }

    button {
      margin-top: 15px;
      border: $standard-border;
      outline: none;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .primary_border {
      border-color: $primary-color;
    }

    .button__primary {
      margin: auto;
      border: initial;

      &:disabled {
        border: 1px solid $shade-color;
      }

      &.disabled {
        background-color: $primary-color;
        color: white;
      }
    }


  }

  &__credit-card {

    .form_element {
      background-color: $bg-standard;
    }

    .payment__title {
      font-size: $fz-26;
      font-weight: $fw-800;
    }

    .payment__methods__body__button {
      width: 100%;
      border: 1px solid $primary-darker;
      cursor: unset;
    }

    &__info {
      display: flex;
      gap: 10px;

      &__element {
        text-align: center;
        font-weight: $fw-standard;
        font-size: $fz-standard;
        line-height: $lh-standard;
      }
    }

    &__date {
      position: relative;
      display: flex;
      align-self: center;
      background-color: $bg-standard;
      border-radius: 50px;
      border: 1px solid $shade-color;
      padding: 0 30px;
      overflow: hidden;
      height: 42px;

      ion-icon {
        height: 100%;
        position: absolute;
        right: 10px;
      }

      input {
        outline: none;
        border: none;
        max-width: 40px;
        text-align: center;

        &::placeholder {
          color: $shade-darker;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type=number] {
          -moz-appearance: textfield;
        }
      }

      p {
        margin: 0 10px;
        display: flex;
        align-items: center;
        color: $shade-darker;
      }
    }
  }

  &__title {
    font-size: $fz-standard;
    font-weight: $fw-800;
    margin: 0;

    &:empty {
      padding-bottom: 0;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 100px 0 30px;
    height: 90px;
    align-items: center;
  }

  &__information {
    align-items: center;
    font-weight: $fw-standard;
    font-size: $fz-12;
    line-height: $lh-16;

    ion-icon {
      margin-right: 5px;
    }

    &.hide {
      display: none;
    }
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 50px;

    &__element {
      display: flex;
      align-items: center;
      flex-grow: 1;

      hr {
        display: block;
        width: 100%;
        height: 1px;
        border: none;
        background: $shade-color;

        &.active {
          background-color: $secondary-color;
        }
      }

      &:last-of-type {
        flex-grow: 0;
      }

      .btn-text {
        font-size: unset;
      }
    }

    &__container {
      background-color: $shade-light;
      box-shadow: $dropdown-shadow;
      padding: 55px 60px 40px 60px;
      height: fit-content;

      .form_element.text {
        background-color: $bg-standard;
      }

      @media screen and (max-width: 750px) {
        padding: 20px;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        min-height: unset;
      }

      .enter {

        &__header {
          margin: 0 0 20px;

          h2 {
            margin: 0;
            font-size: $fz-22;
          }
        }

        .button__secondary {
          display: none;
        }

        .login_form {
          width: 100%;

          .form_element.text {
            height: 50px;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      margin: 50px 0 0 0 !important;
      padding: 0 !important;
      text-decoration: none !important;
      text-align: left !important;
      gap: 30px;

      button {
        min-width: fit-content;
      }

      &__description {
        font-size: 16px;
        font-weight: 400;
        margin: 0;

        span {
          color: $secondary-color;
          cursor: pointer;

          @media screen and (max-width: 900px) {
            display: block;
          }

          @media screen and (max-width: map-get($breakpoints, small)) {
            display: initial;
          }

          @media screen and (max-width: map-get($breakpoints, xs)) {
            display: block;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__login {

    &__user-exist {
    }

    .password_forgotten {
      width: inherit;
      margin-bottom: 20px;
    }

    .error {
      margin-left: 60px;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }

    .payment__title {
      font-size: $fz-26;
      font-weight: $fw-800;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        display: none;
      }
    }

    .payment__subtitle {
      font-size: 16px;
      font-weight: $fw-standard;
      margin-top: 50px;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        font-size: $fz-standard;
      }
    }

    .form_element {
      margin: 10px 0;
      max-width: 430px;
      width: 100%;
      height: 50px;

      &.text {
        background-color: $bg-standard;
      }

      .button__icon {
        margin-top: 0 !important;
      }
    }

    .button__primary {
      margin-left: auto;

      &:disabled {
        background-color: $primary-color;
        color: white;
        border-color: $bg-transparent;
      }
    }

    .form_element__input-text {
      font-size: 16px;
    }
  }

  &__windows {
    display: grid;
    grid-template-columns: 1.5fr 2fr;
    margin-top: 65px;
    gap: 30px;

    #current-title {
      display: none;
      font-size: 18px;
      margin: 0;
      font-weight: $fw-700;
      padding: 0 20px;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        display: block;
      }
    }

    .payment__title {
      font-size: $fz-26;
    }

    @media screen and (max-width: map-get($breakpoints, medium)) {
      gap: 10px;
    }

    @media screen and (max-width: map-get($breakpoints, small)) {
      display: block;
    }

    @media screen and (max-width: map-get($breakpoints, xs)) {
      margin-top: 20px;
    }
  }

  &__bucket {
    font-size: $fz-standard;
    font-weight: $fw-standard;

    .toggle-bucket {
      display: none;

      .payment__bucket__body, .payment__bucket__footer {
        @media screen and (max-width: map-get($breakpoints, xs)) {
          display: block;

          .payment__bucket__title {
            display: none;
          }
        }
      }

      @media screen and (max-width: map-get($breakpoints, xs)) {
        display: block;
      }

      &__header {
        display: flex;
        gap: 10px;
        padding: 25px 20px;

        &__title {
          ion-icon {
            visibility: visible;
          }

          display: flex;
          gap: 5px;
          align-items: center;
          font-weight: $fw-standard;
          font-size: $fz-standard;
          line-height: $lh-standard;
          margin: 0;
        }

        &__total {
          margin-left: auto;
        }
      }

      .fade {
        &-enter-active, &-leave-active {
          transition: opacity .5s;
        }

        &-enter, &-leave-to {
          opacity: 0;
        }
      }

      .drop {
        &-enter-active {
          transition: all 1.3s;
        }

        &-leave-active {
          transition: all 1.3s;
        }

        &-enter {
          height: 0;
        }

        &-leave-to {
          height: 0;
        }
      }

      &__body {
        height: auto;
      }
    }

    &__body {
      padding: 0 20px;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        display: none;
      }
    }

    &__title {
      margin-top: 55px;
      font-weight: $fw-800;
      font-size: $fz-26;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        font-size: 18px;
        margin-top: 5px;
      }
    }

    &__footer {
      text-align: center;
      padding: 22px 0;
      margin-top: 25px;
      background-color: $secondary-light;
      box-shadow: $dropdown-shadow;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        display: none;
      }

      p {
        margin: 0;
      }
    }
  }

  &__footer {
    margin: 20px 0 0 0;
    font-weight: $fw-standard;
    font-size: $fz-standard;

    & .payment__footer__back-btn {
      color: $fg-standard;
      width: fit-content;
      cursor: pointer;
      text-decoration: none;

      @media screen and (max-width: map-get($breakpoints, medium)) {
        margin-left: auto;
      }

      @media screen and (max-width: map-get($breakpoints, small)) {
        margin-left: auto;
      }

      @media screen and (max-width: map-get($breakpoints, xs)) {
        margin-left: auto;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__list {
    list-style: none;
    padding: 0;

    @media screen and (max-width: map-get($breakpoints, small)) {
      margin-top: 0;
    }

    &__item {
      display: flex;
      align-items: center;
      gap: 10px;
      border-bottom: 1px solid $shade-color;
      padding: 30px 0;

      &:first-child {
        @media screen and (max-width: map-get($breakpoints, xs)) {
          padding-top: 0;
        }
      }

      &__coupon {
        text-align: right;
        display: none;
      }

      &__button {
        color: $primary-color;
        margin-top: 10px;

        &:hover {
          color: $primary-darker;

          span {
            text-decoration: underline;
          }

        }
      }

      &__form {
        box-shadow: $dropdown-shadow;
        background-color: $shade-light;
        display: grid;
        grid-template-columns: 1fr 110px;
        padding: 25px 10px;
        margin-top: 5px;

        .payment__list__item__button {
          margin-top: 0;
        }

        .form_element {
          margin-bottom: 0;
        }
      }

      &__sum {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 30px;

        p {
          margin: 0;

          &:nth-child(2n) {
            text-align: right;
          }

          &:last-child {
            font-weight: $fw-700;
          }
        }
      }

      &__icon {
        align-self: flex-start;
        margin-top: 2px;

        img[alt=''] {
          opacity: 0;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
      }

      &__title {
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;

        @media screen and (max-width: 1200px) {
          max-width: 250px;
        }

        @media screen and (max-width: map-get($breakpoints, medium)) {
          max-width: 200px;
        }

        @media screen and (max-width: map-get($breakpoints, xs)) {
          max-width: 250px;
        }
      }

      &__source {
        display: flex;
        margin: 0;
        white-space: nowrap;

        span {
          max-width: 220px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: text;

          @media screen and (max-width: map-get($breakpoints, medium)) {
            max-width: 110px;
          }

          @media screen and (max-width: map-get($breakpoints, xs)) {
            max-width: 180px;
          }

          a {
            pointer-events: none;
            color: inherit;
          }
        }

        &__pipe {
          margin: 0 2px;
        }
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &__price {
        margin-left: auto;
        align-self: end;
        white-space: nowrap;
      }
    }
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    color: white;
    font-size: $fz-14;

    @media screen and (max-width: map-get($breakpoints, small)) {
      color: $secondary-color;
      background-color: $bg-standard;
    }
  }

  &__step {
    min-width: fit-content;
    padding: 4px 12px 4px 4px;
    gap: 10px;
    color: $secondary-color;
    background-color: $bg-transparent;
    border: 1px solid $secondary-color;

    &:disabled {
      background-color: $bg-standard;
      color: $shade-color;
      border-color: $shade-color;

      .circle {
        background-color: $shade-color;

        @media screen and (max-width: map-get($breakpoints, small)) {
          color: $shade-color;
          background-color: $bg-standard;
        }
      }

      &:hover {
        background-color: $bg-standard !important;
        color: $shade-color;
        border-color: $shade-color;

        .circle {
          background-color: $shade-color;

          @media screen and (max-width: map-get($breakpoints, small)) {
            color: $shade-color;
            background-color: $bg-standard;
          }
        }
      }
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04) !important;
      color: $secondary-dark;
      border-color: $secondary-dark;

      @media screen and (max-width: map-get($breakpoints, small)) {
        background: $bg-transparent !important;
      }

      .circle {
        background-color: $secondary-dark;

        @media screen and (max-width: map-get($breakpoints, small)) {
          color: $secondary-dark;
          background-color: $bg-standard;
        }
      }
    }

    &.active {
      background: $secondary-color !important;
      color: white;
      border-color: $secondary-color;

      .circle {
        color: $secondary-color;
        background-color: white;

        @media screen and (max-width: map-get($breakpoints, small)) {
          color: white;
          background-color: $secondary-color;
        }
      }

      &:hover {
        background: $secondary-dark !important;
        border-color: $secondary-dark;

        .circle {
          color: $secondary-dark;

          @media screen and (max-width: map-get($breakpoints, small)) {
            color: white;
            background-color: $secondary-dark;
          }
        }
      }
    }
  }

  &__logo {
    height: 32px;
    width: 168px;
    cursor: pointer;
  }

  &__message {
    color: $fg-standard;
    font-weight: $fw-standard;
    margin: 0;
  }

  &__container {
    padding: 0 100px;
    margin: 50px auto;
    max-width: 1400px;
    width: 100%;

    .payment__footer {
      text-align: right;

      @media screen and (max-width: map-get($breakpoints, xs)) {
        display: block;
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 1200px) {
      padding: 0 20px;
    }

    @media screen and (max-width: map-get($breakpoints, xs)) {
      margin-top: 0;
      padding: 0;
    }
  }

  &__hint {
    color: $shade-dark;
    font-size: $fz-12;
    text-align: justify;
    font-weight: $fw-standard;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    border-top: $light-border;
    margin: 20px -30px 0;
    column-gap: 10px;
    padding: 30px 30px 0;
    user-select: none;
  }

  &__terms-and-conditions {
    display: flex;
    flex-direction: column;

    .button__primary {
      tab-index: 1;

      &:disabled {
        background-color: $primary-color;
        color: white;
      }
    }

    @media screen and (max-width: map-get($breakpoints, xs)) {
      .payment__title {
        display: none;
      }
    }

    .error {
      margin-top: 10px;
    }

    .payment__title {
      padding-bottom: 0;
    }
  }

  &__section {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 30px;

    .mandatory, .disclaimer {
      margin-left: auto;
      font-weight: $fw-standard;
      font-size: $fz-12;
      line-height: $lh-16;
      color: $shade-color;
    }

    .disclaimer {
      margin-top: -20px;
    }

    .button__primary {
      margin-left: auto;
    }

    a {
      color: $primary-color !important;

      &:hover {
        text-decoration: underline;
      }
    }

    .form_element {
      margin-bottom: 0;

      &__input-checkbox {
        visibility: hidden;
      }

      &__label {
        display: grid;
        grid-template-columns: 24px 1fr;
        align-items: start;
        gap: 10px;

        span {
          grid-column: 1;
          grid-row: 1;
        }

        &__text {
          font-weight: $fw-standard;
          font-size: $fz-standard;
          line-height: $lh-standard;
          text-align: justify;
          margin: 0;
          grid-column: 2 !important;
        }
      }
    }

    &:nth-child(1) {
      margin-top: 50px;
    }

    &__element {
      display: flex;
      align-items: flex-start;
      gap: 10px;

      .payment__link {
        height: fit-content;
      }

      &__title {
        margin: 0;
        font-size: $fz-standard;
        line-height: $lh-standard;
        font-weight: $fw-standard;
      }

      &__item {
        font-weight: $fw-700;
      }
    }

    .payment__link, ion-icon {
      color: $secondary-color;
    }
  }

}

#orderRequestForm {
  .payment__actions {
    @media screen and (max-width: map-get($breakpoints, 'xs')) {
      flex-direction: column-reverse;
      align-items: flex-end;
      row-gap: 10px;
    }
  }
}
