.recommendation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  &__title {
    font-style: $fs-standard;
    font-weight: $fw-700;
    font-size: $fz-standard;
    line-height: $lh-standard;
    margin-bottom: 20px;

    display: flex;
    align-items: center;


    color: $fg-standard;
  }

  &__articles {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    overflow: hidden;
    position: relative;
    height: 350px;
    justify-content: space-between;
    align-content: center;

  }

  &__leftArrow {
    z-index: 15;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: $bg-standard;
    box-shadow: $active-shadow;
    border-radius: 20px;
  }

  &__rightArrow {
    margin-right: 10px;
    z-index: 15;
    padding: 8px;
    width: 40px;
    height: 40px;
    background: $bg-standard;
    box-shadow: $active-shadow;
    border-radius: 20px;
  }

  &__article {
    z-index: 3;
    display: flex;
    flex-direction: row;

    overflow: hidden;
    position: absolute;

    &__main {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      min-width: 200px;

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0 10px;
      background: $shade-light;
      border-radius: $border-radius-10;

      &__group {
        //min-width: 175px;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 20px;

        &__image {
          filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
          width: 135px;
          height: 190px;

          &__tag {
            background: $secondary-color;
            position: absolute;
            right: 0;
            top: calc(200px - 50px);
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 2px 5px;

            p {
              font-style: $fs-standard;
              font-weight: $fw-600;
              font-size: $fz-12;
              line-height: $lh-16;
              color: $bg-standard;
              flex: none;
              order: 0;
              flex-grow: 0;
              margin: 0 10px;
            }
          }
        }

        &__text {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          max-width: 140px;
          font-style: $fs-standard;
          font-weight: $fw-600;
          font-size: $fz-12;
          line-height: $lh-16;


          &__title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            height: 32px;

            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 5px 0 1px 0;

            p {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;

              margin: 0;
            }
          }

          &__undertitle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;

            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 1px 0;

            p {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: $shade-dark;
              margin: 0;
            }
          }

          &__court {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0;

            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 1px 0 5px;

            p {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: $shade-dark;
              margin: 0;
            }
          }
        }
      }
    }
  }

}
